/* NORMALIZE */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  outline: 0;
}
h1 {
  font-size: 2em;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: 700;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
q {
  quotes: 2 1c 2 1d 2 18 2 19;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
button,
input {
  line-height: normal;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='search'] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body,
figure {
  margin: 0;
}
legend,
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}
* {
  box-sizing: border-box;
}
img {
  max-width: 100%;
}

/* INITIAL */

html {
  font-size: 10px;
  font-family: sans-serif;
}
p {
  font-size: 1.6rem;
  line-height: 1.5;
}
h1 {
  font-family: billabong, 'billabongregular';
  text-align: center;
  font-weight: 100;
  font-size: 13rem;
  margin: 2rem 0;
  letter-spacing: -1px;
  text-shadow: 0px 4px 0 rgba(18, 86, 136, 0.11);
}
@media (max-width: 580px) {
  h1 {
    font-size: 8rem;
  }
}
h1 a {
  color: #125688;
  text-decoration: none;
}
h1 a:focus {
  outline: 0;
}
@font-face {
  font-family: 'billabongregular';
  src: url('https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.eot');
  src: url('https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.woff')
      format('woff'),
    url('https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.ttf')
      format('truetype'),
    url('https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.svg#billabongregular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

/* ANIMATIONS */

.likes-heart {
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(5);
          transform: translateX(-50%) translateY(-50%) scale(5);
  display: block;
}
.likes-heart.like-enter {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
          transform: translateX(-50%) translateY(-50%) scale(1);
  opacity: 1;
}
.likes-heart.like-enter.like-enter-active {
  -webkit-transform: translateX(-50%) translateY(-50%) scale(5);
          transform: translateX(-50%) translateY(-50%) scale(5);
}
.likes-heart .like-leave-active {
  display: none;
}

/* GENERAL */

body {
  background: #fafafa;
}
.photo-grid {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}
.grid-figure,
.single-photo {
  flex-basis: calc(33.333% - 4rem);
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0 2rem 2rem 2rem;
  padding: 2rem;
  border: 1px solid #edeeed;
  background: #fff;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.03);
  position: relative;
}
.single-photo {
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  background: #fff;
}
.single-photo .grid-figure {
  box-shadow: none;
  margin: 0 2rem 0 0;
  border: 0;
  padding: 0;
  flex: 1 0 60%;
  max-width: 60%;
}
.single-photo .comments {
  flex: 1 0 40%;
  max-width: 40%;
}
.single-photo .grid-photo {
  width: 100%;
  margin: 0;
}
.grid-photo {
  width: calc(100% + 4rem);
  margin-left: -2rem;
  margin-top: -2rem;
  max-width: none;
}
.remove-comment {
  background: none;
  border: 0;
  line-height: 1;
  opacity: 0;
}
.remove-comment:hover {
  color: #f00;
}
.comment {
  border-bottom: 1px solid #edeeed;
  padding: 0.5rem 0;
}
.comment p {
  font-size: 1.2rem;
  margin: 0;
}
.comment strong {
  color: #125688;
  margin-right: 5px;
}
.comment:hover .remove-comment {
  opacity: 1;
}
.comment-form input,
.comment-form textarea {
  width: 100%;
  border: 0;
  font-size: 1.3rem;
  padding: 1rem 0;
  border-bottom: 1px solid #edeeed;
  outline: none;
  resize: vertical;
}
.grid-photo-wrap {
  position: relative;
}
.likes-heart {
  background: #fff;
  border-radius: 100%;
  font-size: 2rem;
  padding: 1rem;
  position: absolute;
  color: #125688;
  left: 50%;
  top: 50%;
  pointer-events: none;
}
/*  
  Buttons  
*/
.control-buttons {
  display: flex;
  justify-content: space-between;
}
button,
.button {
  border: 2px solid #f2f2f2;
  background: none;
  flex-basis: 48%;
  display: inline-block;
  line-height: 2;
  text-decoration: none;
  padding: 5px;
  text-align: center;
  font-size: 15px;
  color: #125688;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  box-sizing: padding-box;
}
button:hover,
.button:hover,
button:focus,
.button:focus {
  border-color: #125688;
  outline: 0;
}
/*
  Cowboy style speech bubble - you should probably use an SVG for this if you are doing more icons.
*/
.speech-bubble {
  width: 1.5rem;
  height: 1.25rem;
  background: #125688;
  display: inline-block;
  border-radius: 50%;
  position: relative;
}
.speech-bubble:after {
  display: inline-block;
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 1.25rem 1.25rem 0;
  border-color: transparent #125688 transparent transparent;
  top: 30%;
  left: 0;
}

